
import {
    defineComponent,
    watch,
    Ref,
    ref,
    computed,
    PropType
} from 'vue';
import {
    listPagination,
    ListHeaderItem,
    RequestData,
    ListContent
} from '@/components/common/list';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import setCommunityDialog from '@/components/view/super/set-community-dialog';
import { account as accountRequest, update } from '@/data/index';
import updateBillingList from '@/components/view/common/dialog/update-billing-list';
import { superBaseRouter } from '@/router';
import { userForSuperApi } from '@/api';
import {
    getMenus, ProjectType, changePath,
    getProjectPageData
} from './util';

interface DisWithInstallerItem {
    ID: string;
    Account: string;
    Children: Array<object>;
}

export default defineComponent({
    components: {
        listPagination,
        setCommunityDialog,
        updateBillingList,
        secondaryNav
    },
    props: {
        type: {
            type: String as PropType< ProjectType >,
            default: 'community'
        }
    },
    setup(props) {
        // 新增头部标签community\office
        const secondMenus = getMenus(superBaseRouter);
        const activeNavItem = computed(() => props.type);
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const searchName: Ref< string > = ref('');
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const projectPageData = getProjectPageData();
        watch(activeNavItem, () => {
            const pageData = projectPageData[activeNavItem.value];
            headers.value = pageData.header;
            searchName.value = pageData.searchName;
            listRequestData.value = pageData.requestData;
        }, {
            immediate: true
        });
        const updateToList = ref(0);

        const disWithInstaller: Ref<Array<DisWithInstallerItem>> = ref([]);
        const installerArray: Ref<Array<object>> = ref([]);
        const isShowCommunityDialog = ref(false);
        const setCommunityFormData: Ref<object> = ref({});
        const initData = ref<Array<ListContent>>([]);

        accountRequest.getRequestDisIncludeInstaller((data: Array<DisWithInstallerItem>) => {
            disWithInstaller.value = data;
        });

        watch(computed(() => listRequestData.value.param.AreaManage), () => {
            disWithInstaller.value.forEach((element) => {
                if (element.ID === listRequestData.value.param.AreaManage) {
                    installerArray.value = element.Children;
                }
            });
        });

        const communityID = ref('');
        const setCommunity = (listItem: object) => {
            isShowCommunityDialog.value = true;
            setCommunityFormData.value = listItem;
            communityID.value = JSON.parse(JSON.stringify(listItem)).ID;
        };

        const isShowUpdatingListDialog = ref(false);

        // 获取所有刷新社区
        const getUpdateList = () => {
            isShowCommunityDialog.value = false;
            userForSuperApi.updateBillingList({
                ID: communityID.value,
                Step: update.stepForGetList,
                Type: activeNavItem.value === 'community' ? update.updateForCommunity : update.updateForOffice
            }, [(res: {
                data: {
                    detail: Array<ListContent>;
                };
            }) => {
                initData.value = res.data.detail;
                isShowUpdatingListDialog.value = true;
            }, false]);
        };
        // 确认刷新计费模型
        const confirmUpdate = () => {
            userForSuperApi.updateBillingList({
                ID: communityID.value,
                Step: update.stepForUpdate,
                Type: activeNavItem.value === 'community' ? update.updateForCommunity : update.updateForOffice
            }, () => {
                isShowUpdatingListDialog.value = false;
            });
        };

        return {
            headers,
            disWithInstaller,
            installerArray,
            updateToList,
            listRequestData,
            isShowCommunityDialog,
            setCommunityFormData,
            setCommunity,
            isShowUpdatingListDialog,
            initData,
            getUpdateList,
            confirmUpdate,
            secondMenus,
            activeNavItem,
            changePath,
            searchName
        };
    }
});
