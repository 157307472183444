import router from '@/router';
import {
    ListHeaderItem,
    RequestData
} from '@/components/common/list';

type ProjectType = 'community' | 'office';

type ProjectPageData = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
        searchName: string;
    }
};

// 7.0 新增办公板块
function getMenus(basicRouter: string) {
    return [{
        label: WordList.ProperAllCommunity,
        key: 'community',
        path: `/${basicRouter}/project?type=community`
    }, {
        label: WordList.ProperAllOffice,
        key: 'office',
        path: `/${basicRouter}/project?type=office`
    }];
}

const changePath = (key: string, path: string) => {
    router.push(path);
};

const getProjectPageData = () => {
    const communityHeaders: Array<ListHeaderItem> = [{
        label: WordList.TabelCommunitiesName,
        name: 'Location'
    }, {
        label: WordList.ProperAllTextInstaller,
        name: 'Install'
    }, {
        label: WordList.ProperAllTextRoleDistributor,
        name: 'AreaManage'
    }, {
        label: WordList.ProperAllTextNumbersOfApartments,
        name: 'Apts'
    }, {
        label: WordList.ProperAllTextNumbersOfIndoorMonitors,
        name: 'Indoors'
    }, {
        label: WordList.ProperAllTextFeaturePlan,
        name: 'FeatureName'
    }];

    const officeHeaders: Array<ListHeaderItem> = [{
        label: WordList.ProperAllTextOfficeName,
        name: 'Location'
    }, {
        label: WordList.ProperAllTextInstaller,
        name: 'Install'
    }, {
        label: WordList.ProperAllTextRoleDistributor,
        name: 'AreaManage'
    }, {
        label: WordList.ProperAllTextNumOfUsers,
        name: 'Peoples'
    }, {
        label: WordList.ProperAllTextNumOfDevices,
        name: 'Devices'
    }, {
        label: WordList.ProperAllTextFeaturePlan,
        name: 'FeatureName'
    }];

    const communityRequestData: RequestData = {
        url: 'v3/web/community/project/getList',
        param: {
            Install: 'all',
            AreaManage: 'all',
            Key: ''
        }
    };
    const officeRequestData: RequestData = {
        url: 'v3/web/office/project/getOfficeList',
        param: {
            Install: 'all',
            AreaManage: 'all',
            Key: ''
        }
    };
    const pageData: ProjectPageData = {
        community: {
            header: communityHeaders,
            requestData: communityRequestData,
            searchName: WordList.TabelCommunitiesName
        },
        office: {
            header: officeHeaders,
            requestData: officeRequestData,
            searchName: WordList.ProperAllTextOfficeName
        }
    };
    return pageData;
};

export default null;
export {
    getMenus,
    ProjectType,
    changePath,
    getProjectPageData
};